<template>
  <div :is="layout">
    <v-container>
      <div id="cesiumContainer"></div>
    </v-container>
  </div>
</template>
<script>
import flight from "../flightInformation";
export default {
  components: {
    flight,
  },
  data() {
    return {
      infoId: null,
      controlId: null,
      holes: [],
      layout: null,
      positions: [],
      theSector: [],
      location:{}
    };
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.controlId = this.$route.params.id;
    this.infoId = this.$route.params.info_id;
    flight.flightInformation.forEach((item, index) => {
      if (item.id == this.infoId) {
       item.control_area.forEach((ele) => {
          if (ele.id == this.controlId) {
            this.positions=ele.location
            this.theSector = ele.sector
          }
        });
      }
    });
    this.$nextTick(() => {
      var viewer = new this.Cesium.Viewer("cesiumContainer");
      viewer.camera.setView({
            destination: this.Cesium.Cartesian3.fromDegrees(111.07, 39.05,10000000)
        });
     this.theSector.forEach((item) => {
        this.location = {
          positions: this.Cesium.Cartesian3.fromDegreesArray(item.location),
        };
        this.holes.push(this.location);
      });
      viewer.entities.add({
        // 实体的图形
        polygon: {
          //要于此实体相关联的多边形
          hierarchy: {
            //属性指定PolygonHierarchy 多边形层级
            positions: this.Cesium.Cartesian3.fromDegreesArray(
              this.positions
              // 管制区坐标
            ),
            holes: this.holes,
          },
        //   fill:false,
          material: this.Cesium.Color.BLACK.withAlpha(0.1),
          height: 150000,
          extrudedHeight: 0,
          outline: true,
          outlineColor: this.Cesium.Color.BLUE,
        },
      });

     this.theSector.forEach((item1) => {
        viewer.entities.add({
          name: "Sector",
          polygon: {
            hierarchy: {
              positions: this.Cesium.Cartesian3.fromDegreesArray(
                item1.location
              ),
            },
            fill: false,
            material: this.Cesium.Color.BLUE.withAlpha(0.1),
            height: item1.height,
            extrudedHeight: 0,
            outline: true,
            outlineColor: this.Cesium.Color.GREEN,
          },
        });
      });
    });
  },
};
</script>
<style scoped>
#cesiumContainer {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
